import axios from '@axios'
import $http from '@/libs/http'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    addDepositChannel(ctx, param) {
      return new Promise((resolve, reject) => {
        $http.post('/api/v1/channels/create', param)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    showDepositChannel(ctx, param) {
      return new Promise((resolve, reject) => {
        $http.post('/api/v1/channels/list', param)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateDepositChannel(ctx, param) {
      return new Promise((resolve, reject) => {
        $http.post('/api/v1/channels/update', param)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    sortChannel(ctx, params) {
      return new Promise((resolve, reject) => {
        $http.post('/api/v2/admin/channels/sort', params, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    fetchSmileoneGoods() {
      return new Promise((resolve, reject) => {
        $http.post('/api/v2/admin/goods/list', { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },
    saveGoods(ctx, params) {
      return new Promise((resolve, reject) => {
        $http.post('/api/v2/admin/goods/save', params, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },

    configLoad() {
      return new Promise((resolve, reject) => {
        $http.post('/api/v2/admin/config/load', { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },

    configSave(ctx, params) {
      return new Promise((resolve, reject) => {
        $http.post('/api/v2/admin/config/save', params, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },

    sortGoods(ctx, params) {
      return new Promise((resolve, reject) => {
        $http.post('/api/v2/admin/goods/sort', params, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
          .then(response => { resolve(response) })
          .catch(error => reject(error))
      })
    },

  },
}
