<template>
  <div>
    <b-card no-body>
      <div
        v-hasBtn="`product_list`"
        class="m-2"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col md="4">
              <b-button
                id="toggle-btn"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                @click="addSmileoneGoods('')"
              >
                Add
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <b-table
        ref="refSmileoneGoodsTable"
        :items="fetchSmileoneGoods"
        responsive
        :fields="tableColumns"
        primary-key="transactionId"
        show-empty
        empty-text="No matching records found"
        class="position-relative withdraw-table"
      >
        <template #cell(display_order)="data">
          <b-dropdown
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="flat-primary"
          >
            <template #button-content>
              <feather-icon
                class="align-middle display-order"
                icon="AlignJustifyIcon"
                size="28"
                color="#6b6878"
              />
            </template>
            <b-dropdown-item @click="upOneItem(data.item)">
              <feather-icon
                class="align-middle display-order"
                icon="ArrowUpIcon"
                size="22"
              />
              <span>Up one position</span>
            </b-dropdown-item>
            <b-dropdown-item @click="downOneItem(data.item)">
              <feather-icon
                class="align-middle display-order"
                icon="ArrowDownIcon"
                size="22"
              />
              <span> Down one position </span>
            </b-dropdown-item>
            <b-dropdown-item @click="upItemTop(data.item)">
              <img
                src="@/assets/images/settings/to_top.png"
                alt="to_top"
                class="display-order-icon"
              >
              <span> Up to top </span>
            </b-dropdown-item>
            <b-dropdown-item @click="downItemBottom(data.item)">
              <img
                src="@/assets/images/settings/to_bottom.png"
                alt="to_bottom"
                class="display-order-icon"
              >
              <span> Down to bottom </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <!-- Column: link -->
        <template #cell(link)="data">
          <div style="width: 320px">
            {{ data.value }}
          </div>
        </template>
        <!-- Column: pic_link -->
        <template #cell(pic_link)="data">
          <div style="width: 320px">
            {{ data.value }}
          </div>
        </template>
        <!-- Column: channelType -->
        <template #cell(channelType)="data">
          {{ channelTypes[data.value] }}
        </template>
        <!-- Column: created_at -->
        <template #cell(created_at)="data">
          {{ Number(data.value) ? updateDate(data.value/1000, timeZone) : '' }}
        </template>
        <!-- Column: updatedAt -->
        <template #cell(updatedAt)="data">
          {{ updateDate(data.value, timeZone) }}
        </template>
        <!-- Column: channelType -->
        <template #cell(status)="data">
          {{ data.item.status === 1 ? 'Active' : 'Non-active' }}
        </template>
        <!-- Column: status -->
        <!-- <template #cell(status)="data">
          {{ statusOptions[data.value] }}
        </template> -->
        <template #cell(edit)="data">
          <div
            v-hasBtn="`product_list`"
            class="d-flex"
          >
            <b-button
              :variant="data.item.status == 1?'outline-success':'outline-secondary'"
              @click="addSmileoneGoods(data.item)"
            >
              Edit
            </b-button>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="changePagination"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- modal -->
    <b-modal
      ref="add-smileone-goods"
      :title="addTitle"
      size="lg"
      ok-only
      ok-title="Confirm"
      cancel-variant="outline-secondary"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form
        ref="form"
        class="mt-2"
        @submit.stop.prevent="handleSubmit"
      >
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="name-input">PRODUCT NAME</label>
              </div>
              <b-form-input
                v-model="smileoneGoodsModal.name"
                autocomplete="off"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="name-input">COUNTRY</label>
              </div>
              <v-select
                v-model="smileoneGoodsModal.iso_code"
                :options="countryOptions"
              />
            </b-form-group></b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="name-input">PRODUCT PRICE</label>
              </div>
              <b-form-input
                v-model="smileoneGoodsModal.price"
                autocomplete="off"
                type="number"
                @input="value => value.replace(/[^\d+]/, '')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group>
              <div>
                <div class="d-flex justify-content-between">
                  <label>STATUS</label>
                </div>
                <b-form-checkbox
                  v-model="smileoneGoodsModal.status"
                  checked="true"
                  name="check-button"
                  switch
                  inline
                />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="name-input">PRODUCT LINK</label>
              </div>
              <b-form-textarea
                v-model="smileoneGoodsModal.link"
                autocomplete="off"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="name-input">DISPLAY PICTURE LINK</label>
              </div>
              <b-form-textarea
                v-model="smileoneGoodsModal.pic_link"
                autocomplete="off"
                rows="3"
              />
            </b-form-group>
          </b-col>
          <!-- <b-col
            cols="12"
            sm="6"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="name-input">CREATE TIME</label>
              </div>
              <b-form-input
                v-model="smileoneGoodsModal.created_at"
                autocomplete="off"
              />
            </b-form-group>
          </b-col> -->
        </b-row>
        <b-row>
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="name-input">PRODUCT DESCRIPTION</label>
              </div>
              <b-form-textarea
                v-model="smileoneGoodsModal.description"
                autocomplete="off"
                rows="3"
              />
            </b-form-group>
          </b-col>

        </b-row>
      </form>
    </b-modal>
  </div>
</template>
<script>
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BForm,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  // BForm,
  // BFormSelect,
  // BListGroup,
  // BListGroupItem,
  // BTooltip,
  BModal,
  VBModal,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { createNamespacedHelpers } from 'vuex'
import { updateDate } from '@/libs/utils'
import store from '@/store'
import settingsStoreModule from './settingsStoreModule'
import useSmileoneGoods from './useSmileoneGoods'

const { mapState: mapStateWallet } = createNamespacedHelpers('wallet')
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BForm,
    BPagination,
    BFormGroup,
    BFormInput,
    BModal,
    BFormCheckbox,
    BFormTextarea,
    // BForm,
    // BFormSelect,
    // BListGroup,
    // BListGroupItem,
    // BTooltip,
    vSelect,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  computed: {
    ...mapStateWallet(['timeZone']),
  },
  watch: {
    timeZone() {
      // this.fetchSmileoneGoods()
    },
  },
  mounted() {
    // this.fetchSmileoneGoods()
  },
  setup() {
    // 注册vuex
    const SettingsStoreModuleName = 'settings'
    // Register module
    if (!store.hasModule(SettingsStoreModuleName)) { store.registerModule(SettingsStoreModuleName, settingsStoreModule) }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SettingsStoreModuleName)) { store.unregisterModule(SettingsStoreModuleName) }
    })

    const statusOptions = { 1: 'Active', 2: 'Non-active' }
    const channelTypes = { 1: 'Withdraw', 2: 'Deposit' }
    const countryOptions = [{ label: 'Brazil', value: 'BRA' }, { label: 'Mexico', value: 'MEX' }]

    const {
      refSmileoneGoodsTable,
      tableColumns,
      dataMeta,
      perPage,
      totalList,
      currentPage,
      changePagination,

      isEdit,
      addTitle,

      addSmileoneGoods,
      resetModal,
      handleOk,
      smileoneGoodsModal,
      fetchSmileoneGoods,
      handleSubmit,
      refetchData,
      upOneItem,
      downOneItem,
      upItemTop,
      downItemBottom,
    } = useSmileoneGoods()

    return {
      refSmileoneGoodsTable,
      tableColumns,
      dataMeta,
      perPage,
      totalList,
      currentPage,
      changePagination,

      isEdit,
      addTitle,

      channelTypes,
      statusOptions,
      countryOptions,
      updateDate,

      smileoneGoodsModal,

      addSmileoneGoods,
      resetModal,
      handleOk,
      fetchSmileoneGoods,
      handleSubmit,
      refetchData,
      upOneItem,
      downOneItem,
      upItemTop,
      downItemBottom,
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
.invoice-filter-select {
  min-width: 190px;
  width: 100%;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.deposit-channel-status {
  line-height: 24px;
}
// 排序
.display-order {
  cursor: pointer;
}
.display-order-icon {
  width: 18px;
  height: auto;
  margin: 0 2px;
}
</style>
