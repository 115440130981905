import { ref, getCurrentInstance, computed } from '@vue/composition-api'
import { showToast } from '@/libs/utils'
import store from '@/store'

export default function useSmileoneGoods() {
  const { proxy } = getCurrentInstance()
  // table
  const refSmileoneGoodsTable = ref(null)
  const tableColumns = [
    { key: 'display_order', label: 'display order' },
    { key: 'name', label: 'PRODUCT NAME' },
    { key: 'iso_code', label: 'country' }, // todo
    { key: 'price', label: 'PRODUCT PRICE' }, // todo
    { key: 'link', label: 'PRODUCT LINK' }, // todo
    { key: 'pic_link', label: 'DISPLAY PICTURE LINK' }, // todo
    { key: 'description', label: 'PRODUCT DESCRIPTION' },
    { key: 'created_at', label: 'CREATE TIME' },
    { key: 'status', label: 'STATUS' },
    { key: 'edit', label: 'operate' },
  ]
  const perPage = ref(10000)
  const totalList = ref(0)
  const currentPage = ref(1)
  const dataMeta = computed(() => {
    const localItemsCount = refSmileoneGoodsTable.value
      ? refSmileoneGoodsTable.value.localItems.length
      : 0
    return {
      from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })
  const refetchData = () => {
    refSmileoneGoodsTable.value.refresh()
  }
  // modal
  const isEdit = ref(false)
  const addTitle = computed(() => (isEdit.value ? 'Edit Product List Settings' : 'Add Product List'))
  const smileoneGoodsModal = ref({
    name: '',
    iso_code: '',
    price: '',
    link: '',
    pic_link: '',
    description: '',
    created_at: '',
    status: false,
  })
  const currentItem = ref(null)
  const smileoneGoodsList = ref(null)

  const addSmileoneGoods = item => {
    isEdit.value = Boolean(item)
    if (isEdit.value) {
      currentItem.value = item
      const depositArray = ['name', 'price', 'link', 'pic_link', 'description', 'status']
      depositArray.forEach(key => {
        smileoneGoodsModal.value[key] = item[key]
      })
      if (item.iso_code === 'BRA') {
        smileoneGoodsModal.value.iso_code = { label: 'Brazil', value: 'BRA' }
      } else if (item.iso_code === 'MEX') {
        smileoneGoodsModal.value.iso_code = { label: 'Mexico', value: 'MEX' }
      }
      smileoneGoodsModal.value.status = item.status === 1
    } else {
      currentItem.value = null
      const depositArray = ['name', 'price', 'link', 'pic_link', 'description', 'status']
      depositArray.forEach(key => {
        smileoneGoodsModal.value[key] = ''
      })
      smileoneGoodsModal.value.iso_code = { label: 'Brazil', value: 'BRA' }
      smileoneGoodsModal.value.status = false
    }
    proxy.$refs['add-smileone-goods'].show()
  }
  const resetModal = () => {}
  const fetchSmileoneGoods = (ctx, callback) => {
    proxy.$showLoading()
    const res = store.dispatch('settings/fetchSmileoneGoods')
    res
      .then(response => {
        proxy.$hideLoading()
        if (response.data.code == 200) {
          const { list } = response.data.data
          smileoneGoodsList.value = list
          // eslint-disable-next-line no-unused-expressions
          callback && callback(list)
        } else {
          showToast(proxy, 'warning', response.data.message)
        }
      })
      .catch(error => {
        proxy.$hideLoading()
        showToast(proxy, 'warning', error.message)
      })
  }
  const handleOkSubmit = () => {
    proxy.$showLoading()
    let params = {}
    if (isEdit.value) {
      params = {
        data: {
          description: smileoneGoodsModal.value.description,
          id: currentItem.value.id,
          iso_code: smileoneGoodsModal.value.iso_code.value,
          link: smileoneGoodsModal.value.link,
          name: smileoneGoodsModal.value.name,
          pic_link: smileoneGoodsModal.value.pic_link,
          price: smileoneGoodsModal.value.price,
          status: smileoneGoodsModal.value.status ? 1 : 2,
        },
      }
    } else {
      params = {
        data: {
          description: smileoneGoodsModal.value.description,
          iso_code: smileoneGoodsModal.value.iso_code.value,
          link: smileoneGoodsModal.value.link,
          name: smileoneGoodsModal.value.name,
          pic_link: smileoneGoodsModal.value.pic_link,
          price: smileoneGoodsModal.value.price,
          status: smileoneGoodsModal.value.status ? 1 : 2,
        },
      }
    }
    const res = store.dispatch('settings/saveGoods', params)
    res
      .then(response => {
        proxy.$hideLoading()
        if (response.data.code == 200) {
          showToast(proxy, 'success', response.data.message)
          refetchData()
        } else {
          showToast(proxy, 'warning', response.data.message)
        }
      })
      .catch(error => {
        proxy.$hideLoading()
        showToast(proxy, 'warning', error.message)
      })
    proxy.$nextTick(() => {
      proxy.$refs['add-smileone-goods'].hide()
    })
  }

  const handleOk = bvModalEvt => {
    currentPage.value = 1
    bvModalEvt.preventDefault()
    handleOkSubmit()
  }

  const changePagination = page => {
    currentPage.value = page
    refetchData()
  }

  const handleSubmit = () => {}

  const sortGoodsList = () => {
    const params = []
    smileoneGoodsList.value.forEach((goods, index) => {
      params.push({ id: goods.id, sort: `${index + 1}` })
    })
    const res = store.dispatch('settings/sortGoods', { data: params })
    res
      .then(response => {
        proxy.$hideLoading()
        if (response.data.code === 200) {
          showToast(proxy, 'success', 'sort success')
          refetchData()
        } else {
          showToast(proxy, 'warning', response.data.message)
        }
      })
      .catch(error => {
        proxy.$hideLoading()
        showToast(proxy, 'warning', error.message)
      })
  }

  const upOneItem = item => {
    const goodIndex = smileoneGoodsList.value.findIndex(value => value.id === item.id)
    if (goodIndex === 0) {
      showToast(proxy, 'warning', "Can't be moved upwards.")
      return
    }
    smileoneGoodsList.value.splice(goodIndex, 1)
    smileoneGoodsList.value.splice(goodIndex - 1, 0, item)
    sortGoodsList()
  }

  const downOneItem = item => {
    const goodIndex = smileoneGoodsList.value.findIndex(value => value.id === item.id)
    if (goodIndex === (smileoneGoodsList.value.length - 1)) {
      showToast(proxy, 'warning', "Can't be moved downwards.")
      return
    }
    smileoneGoodsList.value.splice(goodIndex, 1)
    smileoneGoodsList.value.splice(goodIndex + 1, 0, item)
    sortGoodsList()
  }

  const upItemTop = item => {
    const goodIndex = smileoneGoodsList.value.findIndex(value => value.id === item.id)
    if (goodIndex === 0) {
      showToast(proxy, 'warning', "Can't be moved upwards.")
      return
    }
    smileoneGoodsList.value.splice(goodIndex, 1)
    smileoneGoodsList.value.splice(0, 0, item)
    sortGoodsList()
  }

  const downItemBottom = item => {
    const goodIndex = smileoneGoodsList.value.findIndex(value => value.id === item.id)
    if (goodIndex === (smileoneGoodsList.value.length - 1)) {
      showToast(proxy, 'warning', "Can't be moved downwards.")
      return
    }
    const goodsLen = smileoneGoodsList.value.length
    smileoneGoodsList.value.splice(goodIndex, 1)
    smileoneGoodsList.value.splice(goodsLen, 0, item)
    sortGoodsList()
  }

  return {
    refSmileoneGoodsTable,
    tableColumns,
    dataMeta,
    perPage,
    totalList,
    currentPage,
    changePagination,

    isEdit,
    addTitle,
    smileoneGoodsModal,

    addSmileoneGoods,
    resetModal,
    handleOk,
    fetchSmileoneGoods,
    handleSubmit,
    refetchData,
    upOneItem,
    downOneItem,
    upItemTop,
    downItemBottom,
  }
}
